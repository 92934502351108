(function () {
	/**
	 * Use a flag for initialization to avoid compatibility issues
	 * with the WebToffee Cookie Consent plugin.
	 */
	let isInitialized = false;

	window.addEventListener("DOMContentLoaded", () => {
		if (!isInitialized) {
			isInitialized = true;
			init();
		}
	});

	const init = () => {
		const els = document.querySelectorAll(".js-accordion-item");
		[...els].forEach(setupAccordionItem);
	};

	const setupAccordionItem = (el) => {
		const item = createItem(el);

		item.on("click", item.toggle);

		const openAnchorTarget = () => isAnchorTarget(el) && item.open();

		openAnchorTarget();
		window.addEventListener("hashchange", openAnchorTarget, false);
	};

	const createItem = (container) => {
		const controller = container.querySelector(".js-accordion-controller");
		const content = container.querySelector(".js-accordion-content");

		controller.setAttribute("aria-controls", content.getAttribute("id"));
		controller.setAttribute("aria-expanded", "false");
		content.setAttribute("aria-hidden", "true");

		const open = () => {
			container.classList.add("is-open");
			controller.setAttribute("aria-expanded", "true");
			content.setAttribute("aria-hidden", "false");
		};

		const close = () => {
			container.classList.remove("is-open");
			controller.setAttribute("aria-expanded", "false");
			content.setAttribute("aria-hidden", "true");
		};

		const isOpen = () => container.classList.contains("is-open");

		return {
			open,
			close,
			isOpen,
			toggle() {
				!isOpen() ? open() : close();
			},
			on(event, handler) {
				controller.addEventListener(event, handler);
				return () => controller.removeEventListener(event, handler);
			},
		};
	};

	const isAnchorTarget = (el) => {
		const hashId = window.location.hash.replace("#", "");
		return el.getAttribute("id") === hashId;
	};
})();
